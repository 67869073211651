module BKR.Fun.Calculator

open BKR.Fun.AgeClassifications;
open BKR.Fun.AgeCategory
open BKR.Fun.AgeCount
open BKR.Fun.EmployeeBucket
open BKR.Fun.ChildrenAmount

open Rules2019

type Result = {
    IsSuccess: bool;
    Count: int;
 }

let EvaluateRule classifications bucket rule =
    if rule.Max < classifications.TotalChildren || not (rule.Rule classifications) then None
    elif bucket.Employees < MinimalEmployees classifications then Some(MinimalEmployees classifications)
    else Some bucket.Employees

let rec MatchRules categories bucket rules =
    match rules with
    | rule :: remaining ->
        let result = EvaluateRule categories bucket rule
        match result with
        | Some _ -> result
        | None -> MatchRules categories bucket remaining
    | [] -> None

let rec MatchBucket buckets categories =
     match buckets with
     | bucket :: buckets ->
        let result = MatchRules categories bucket bucket.Rules
        match result with
        | Some _ -> result
        | None -> MatchBucket buckets categories
     | [] -> None
     
let TryDetermineEmployees buckets classificationRules ages =
    let initialClassifications = CreateAgeClassifications ages
    let classifications = classificationRules |> Seq.fold (fun classification rule -> rule classification) initialClassifications
    let isWithinRange bucket = ContainsAgeRange classifications.MinAge classifications.MaxAge bucket.AgeCategory
    let ageDistance bucket = AgeDistance classifications.MinAge classifications.MaxAge bucket.AgeCategory
    
    let filteredBuckets = buckets
                          |> Seq.where isWithinRange
                          |> Seq.sortBy (fun x -> ageDistance x, x.Employees)
                          |> Seq.toList

    MatchBucket filteredBuckets classifications

let DetermineEmployeesFor year ages =

    let result1 = match year with
                  | 2019 -> TryDetermineEmployees Buckets2019 ClassificationRules2019 ages
                  | _ -> None

    let result2 = match year with
                  | 2019 -> TryDetermineEmployees Buckets2019 ClassificationRules2019 (ages |> Seq.take (Seq.length ages - 1))
                  | _ -> None

    // If first comparison results to nothing then the whole result is invalid
    let final = match result1, result2 with
                | None       , _           -> None
                | Some count , None        -> Some count
                | Some count1, Some count2 -> Some (max count1 count2)

    match final with
    | Some count -> { IsSuccess = true; Count = count }
    | None -> { IsSuccess = false; Count = 0 }

