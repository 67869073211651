module BKR.Fun.Rules2019

open BKR.Fun.AgeClassifications
open BKR.Fun.Rules

let Max3Babies = MaxBabiesRule 3
let Max4Babies = MaxBabiesRule 4
let Max5Babies = MaxBabiesRule 5
let Max8Babies = MaxBabiesRule 8

let Max9Bso = MaxBsoRule 9
let Max18Bso = MaxBsoRule 18

let Buckets2019 =
    [
        Age0 1 3
        Age0 2 6
        Age0 3 9
        Age0 4 12
                
        Age1 1 5
        Age1 2 10
        Age1 3 15
        Age1 4 16
                
        Age2 1 8
        Age2 2 16
        
        Age3 1 8
        Age3 2 16
        
        Age02Bucket 1 4
        Age02Bucket 2 8
        Age02BucketWithRule 3 14 Max8Babies
        Age02Bucket 4 16
        
        Age03Bucket 1 5
        Age03Bucket 2 10
        Age03BucketWithRules 3 [ Rule 13 Max8Babies; Rule 14 Max4Babies; Rule 15 Max3Babies ]
        Age03Bucket 4 16
        
        Age04Bucket 1 5
        Age04Bucket 2 12
        Age04BucketWithRules 3 [ Rule 14 Max8Babies; Rule 15 Max5Babies; Rule 16 Max3Babies ]
        Age04Bucket 4 16
        
        Age13Bucket 1 6
        Age13Bucket 2 11
        Age13Bucket 3 16
        
        Age14Bucket 1 7
        Age14Bucket 2 13
        Age14Bucket 3 16
        
        Age24Bucket 1 8
        Age24Bucket 2 16
        
        Age47Bucket 1 10
        Age47Bucket 2 20
        
        Age7BsoBucket 1 12        
        Age7BsoBucket 2 24
        Age7BsoBucket 3 30
        
        Age4BsoBucketWithRule 1 11 Max9Bso
        Age4BsoBucketWithRule 2 22 Max18Bso        
    ]

let CombinationCategoryReduceToMaxAge4 classification =
    if classification.MinAge < 4 && classification.MaxAge >= 4 then
        let newAge3 = classification.Age3 + classification.Age4To7 + classification.Age7ToBso
        { classification with Age3 = newAge3; Age4To7 = 0; Age7ToBso = 0; MaxAge = 3 }
    else
        classification

let ClassificationRules2019 =
    [
        CombinationCategoryReduceToMaxAge4
    ]