module BKR.Fun.Rules

open BKR.Fun.AgeClassifications
open BKR.Fun.AgeCategory
open BKR.Fun.EmployeeBucket
open BKR.Fun.ChildrenAmount

let MaxBabiesRule amount classification = classification.Age0 <= amount    
let MaxBsoRule amount classification = classification.Age4To7 <= amount    
let AlwaysRule _ = true
let CreateBucketWithRules from _to employees rules = { AgeCategory = { From = from; To = _to }; Employees = employees; Rules = rules }
let CreateBucket from _to employees children = { AgeCategory = { From = from; To = _to }; Employees = employees; Rules = [ { Max = children; Rule = AlwaysRule } ] }    
let Rule max rule = { Max = max; Rule = rule }

let MaxBsoAge = 14

let Age0 = CreateBucket 0 1
let Age1 = CreateBucket 1 2
let Age02Bucket = CreateBucket 0 2
let Age02BucketWithRule employees children rule = CreateBucketWithRules 0 2 employees [ { Max = children; Rule = rule } ]
let Age2 = CreateBucket 2 3
let Age03Bucket = CreateBucket 0 3
let Age03BucketWithRules = CreateBucketWithRules 0 3
let Age13Bucket = CreateBucket 1 3
let Age3 = CreateBucket 3 4
let Age04Bucket = CreateBucket 0 4
let Age04BucketWithRules = CreateBucketWithRules 0 4
let Age14Bucket = CreateBucket 1 4
let Age24Bucket = CreateBucket 2 4
let Age47Bucket = CreateBucket 4 7

let Age7BsoBucket = CreateBucket 7 MaxBsoAge

let Age4BsoBucketWithRule employees children rule = CreateBucketWithRules 4 MaxBsoAge employees [ { Max = children; Rule = rule } ]