module BKR.Fun.AgeCategory
open System

type AgeCategory = {
    From: int;
    To: int;
}

let ContainsAgeRange min max category = category.From <= min && category.To > max 

let AgeDistance min max category = 
    match min, max with
    | Int32.MaxValue, _              -> None
    | _             , Int32.MinValue -> None
    | min           , max            -> Some (Math.Abs(category.To - min) + Math.Abs(category.From - max))