module BKR.Fun.AgeClassifications
open System
open BKR.Fun.AgeCount

type AgeClassifications = {
    TotalChildren: int
    MinAge: int
    MaxAge: int
    Age0: int
    Age1: int
    Age2: int
    Age3: int
    Age4To7: int
    Age7ToBso: int
}

let CreateInitial = { TotalChildren = 0; MinAge = Int32.MaxValue; MaxAge = Int32.MinValue; Age0 = 0; Age1 = 0; Age2 = 0; Age3 = 0; Age4To7 = 0; Age7ToBso = 0 }

let (./) op1 op2 = float op1 / op2

let MinimalEmployees ages =
        match ages.Age0 with
        | 0 -> 0
        | _ -> int (ceil (ages.Age0 ./ 3.0 + (ages.Age1 ./ 5.0 + ages.Age2 ./ 6.0 + ages.Age3 ./ 8.0) / 1.2))
    
let IncrementTotal age classification =
    { classification with TotalChildren = classification.TotalChildren + age.Count }

let ClassifyAge age classification =
     match (age.Count, age.Age) with
        | 0, _ -> classification
        | _, 0 -> { classification with Age0 = age.Count }
        | _, 1 -> { classification with Age1 = age.Count }
        | _, 2 -> { classification with Age2 = age.Count }
        | _, 3 -> { classification with Age3 = age.Count }
        | _, x when x >= 4 && x < 7 -> { classification with Age4To7 = classification.Age4To7 + age.Count }
        | _, _ -> { classification with Age7ToBso = classification.Age7ToBso + age.Count }
        
let SetMaxAge age classification =
    if age.Count > 0 && age.Age > classification.MaxAge then { classification with MaxAge = age.Age }
    else classification
    
let SetMinAge age classification =
    if age.Count > 0 && age.Age < classification.MinAge then { classification with MinAge = age.Age }
    else classification
    
let AddToClassification age classification = classification |> (ClassifyAge age >> SetMaxAge age >> SetMinAge age >> IncrementTotal age)

let CreateAgeClassifications ages = ages |> Seq.fold (fun classification age -> AddToClassification age classification) CreateInitial